<template>
  <div class="h-100 d-flex justify-center align-center">
    <v-form ref="form" v-model="valid" class="form_container" lazy-validation>
      <div class="mb-2">
        <logo />
      </div>
      <div class="title_container">
        <p class="title">Help us protect your account</p>
        <span class="subtitle">For added security, you'll need to verify your identity. We've sent a verification code
          to {{ obfuscateEmail }}
        </span>
      </div>

      <v-text-field label="Verification Code" color="secondary" v-model="authModel.code" prepend-inner-icon="mdi-key"
        placeholder="code" outlined :rules="[
          (v) => !!v || 'Code is required',

        ]" class="w-100" />


      <button :disabled="!valid || resendLoading || loading" @click.prevent="verifyCode" title="Sign In"
        class="sign-in_btn" :class="!valid ? 'disable-btn' : 'primary lighten-1'">
        <span>Verification du code</span>
      </button>

      <button :disabled="resendLoading || loading" @click.prevent="resendCode" title="Sign In "
        class="sign-in_ggl primary--text">
        <span>Resend code </span>
      </button>
      <!-- <p class="note mb-0">Terms of use &amp; Conditions</p> -->
    </v-form>
  </div>
</template>

<script>
import Logo from "@/components/user/AuthLogo.vue";
import { verifyDoubleAuthentificationCode, resendDoubleAuthentificationCode } from "@/api/user";
import { methods } from "@/utils/constant.js";

export default {
  components: { Logo },
  data() {
    return {
      valid: false,
      loading: false,
      resendLoading: false,
      authModel: {},
    };
  },
  computed: {
    obfuscateEmail() {
      return methods.obfuscateEmail(localStorage.getItem("admin-username"))
    }
  },
  methods: {
    async verifyCode() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await verifyDoubleAuthentificationCode({
            code: this.authModel.code
          });

          methods.dispatchSuccess(this.$store,
            "Welcome " + localStorage.getItem("admin-username"));
          location.reload();
        } catch (error) {
          console.log("login error", error.response);
          methods.dispatchError(
            this.$store,
            error && error.response && error.response.data && error.response.data.message || "Something went wrong"
          );
        }
        this.loading = false;
      }
    },
    async resendCode() {
      this.resendLoading = true;

      try {
        await resendDoubleAuthentificationCode({
          code: this.authModel.code
        });

        methods.dispatchSuccess(this.$store,
          "Verification code sent ");
      } catch (error) {
        methods.dispatchError(
          this.$store,
          error && error.response && error.response.data && error.response.data.message || "Something went wrong"
        );
      }
      this.resendLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}

.form_container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 50px 40px 20px 40px;
  background-color: #ffffff;
  box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  font-family: "Inter", sans-serif;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  color: #212121;
}

.subtitle {
  font-size: 0.725rem;
  max-width: 80%;
  text-align: center;
  line-height: 1.1rem;
  color: #8b8e98;
}

.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 0.75rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.sign-in_btn {
  width: 100%;
  height: 40px;
  border: 0;
  //   background: #115dfc;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 7px;
  outline: none;
  color: #242424;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

.note {
  font-size: 0.75rem;
  color: #8b8e98;
  text-decoration: underline;
}
</style>